/** @param {boolean} [logInfo] */
export function ApiCallErrorHandler(err, logInfo) {
  if (logInfo) {
    console.log("API error:", err);
  }
  let errors = [];
  const statusCode = (err.response && err.response.status) || err.status || -1;
  if (statusCode === 500) {
    errors.push("There was an error accessing the server.");
  } else if (statusCode === 401 || statusCode === 403) {
    errors.push(
      "You do not have permissions to access this feature.\nPlease contact an Administrator for assistance.",
    );
  } else if (statusCode === 404) {
    errors.push("The resource was not found.");
  } else if (statusCode === 400 && err.response && err.response.data) {
    Object.keys(err.response.data).forEach((key) => {
      errors = errors.concat(err.response.data[key]);
    });
  } else {
    errors.push(err.message || "Unknown error.");
  }
  if (logInfo) {
    console.log("API error list:", errors);
  }
  return errors;
}

//Version of ApiCallErrorHandler that returns a single user-appropriate error message
export function ApiCallErrorMessageHandler(err, defaultMsg, joinBy) {
  const statusCode = (err.response && err.response.status) || err.status || -1;

  switch (statusCode) {
    case 401:
    case 403:
      return "You do not have permissions to access this feature. \nPlease contact an Administrator for assistance.";
    case 400:
      if (err.response && err.response.data && err.response.data.messages) {
        const errorMessage = err.response.data.messages.join(joinBy || ",\n");
        if (errorMessage) return errorMessage;
      }
      return defaultMsg || "Sorry, something went wrong.  Please try again.";
    default:
      //500 & other
      return defaultMsg || "Sorry, an unexpected error has occurred.";
  }
}
