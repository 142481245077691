import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import Radio from "../../../../../../shared/Radio";
import ConfirmationModal from "../../../../../../shared/ConfirmationModal";

import TripEventsApi from "../../../../../../../services/resources/TripEventsApi";
import {
  TripRegistrationStatuses,
  TripRegistrationRefundTypes,
} from "../../../../../TripEventConsts";
import { ApiCallErrorMessageHandler } from "../../../../../../../lib/coc-common-scripts";
import { pluralizeText } from "../../../../../../../lib";
import { notify } from "react-notify-toast";
import axios from "axios";
import moment from "moment";

function RegistrationCancellation(props) {
  const {
    isTravelTrip,
    latestCancellationDate,
    onRegistrationUpdated,
    registration: {
      acceptedRejectedDate,
      depositAmount,
      id: registrationId,
      rewardRequest,
      status,
      total,
      tripFee,
    },
  } = props;

  const eligibleForRefund =
    (status === TripRegistrationStatuses.Accepted ||
      status === TripRegistrationStatuses.CheckedIn) &&
    (total > 0 || !!rewardRequest);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [refundType, setRefundType] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const onShowConfirmation = useCallback(() => {
    if (!eligibleForRefund) {
      setRefundType(TripRegistrationRefundTypes.None);
    } else if (!isTravelTrip) {
      const isBeforeLatestCancellation = moment().isBefore(
        moment(latestCancellationDate)
      );

      setRefundType(
        isBeforeLatestCancellation
          ? TripRegistrationRefundTypes.TripFee
          : TripRegistrationRefundTypes.None
      );
    }

    setShowConfirmationModal(true);
  }, [eligibleForRefund, isTravelTrip, latestCancellationDate]);

  const onCloseConfirmation = useCallback(() => {
    setShowConfirmationModal(false);
    setRefundType(undefined);
    setErrorMessage("");
  }, []);

  const onCancelRegistration = useCallback(async () => {
    setErrorMessage("");
    setLoading(true);

    try {
      const updatedRegistration =
        await TripEventsApi.updateTripRegistrationStatus(
          apiSignalRef.current.token,
          registrationId,
          TripRegistrationStatuses.Cancelled,
          undefined,
          refundType
        );

      onRegistrationUpdated(updatedRegistration);
      notify.show("Registration has been cancelled", "success");
    } catch (err) {
      if (!axios.isCancel(err)) {
        setErrorMessage(ApiCallErrorMessageHandler(err));
      }
      setLoading(false);
    }
  }, [onRegistrationUpdated, refundType, registrationId]);

  const tripFeeDescription = rewardRequest
    ? tripFee
      ? "Trip fee and credits"
      : "Credits"
    : "Trip fee";

  const creditsDescription = rewardRequest
    ? `${rewardRequest.creditsToRedeem} ${pluralizeText(
        "Credit",
        rewardRequest.creditsToRedeem
      )}`
    : "";

  const noRefundDescription = rewardRequest ? " (of cash or credits)" : "";

  const getAmountDisplay = useCallback(
    (amount) =>
      creditsDescription
        ? amount
          ? `$${amount} and ${creditsDescription}`
          : creditsDescription
        : `$${amount}`,
    [creditsDescription]
  );

  return (
    <div className="border-top">
      <p className="fw-700 mb-8">Cancellations</p>
      {isTravelTrip ? (
        <>
          <p className="mb-16">
            Cancel the registration and select the appropriate refund option.
            Once a student is cancelled they will no longer be registered for
            the trip, and they cannot be un-cancelled.
          </p>
          <p className="mb-8">Cancellation policy:</p>
          <p className="mb-16">
            • <b>Within 1 business day</b> of the payment being processed -{" "}
            <span className="italic-text">
              Full refund ({tripFeeDescription} + deposit)
            </span>
            <br />• <b>After 1 business day</b>, until the latest cancellation
            date -{" "}
            <span className="italic-text">{tripFeeDescription} only</span>
            <br />• <b>After the latest cancellation date</b> -{" "}
            <span className="italic-text">No refund{noRefundDescription}</span>
          </p>
        </>
      ) : (
        <p className="mb-16">
          The student will be refunded in full up until the latest cancellation
          date. Past the latest cancellation date, no refunds
          {noRefundDescription} will be issued for cancellations. <br />
          If the latest cancellation date has passed and you would like to issue
          a full refund for a cancellation, you may cancel the registration and
          then issue a refund using the 'Issue Partial Refund' button above.
        </p>
      )}
      <button
        className="btn custom-btn btn-delete btn-medium uppercase-text"
        onClick={onShowConfirmation}
      >
        Cancel Registration
      </button>
      {isTravelTrip ? (
        <Modal isOpen={showConfirmationModal} className="modal-container">
          <div className="card trip-student-cancellation-modal">
            <div className="large-text mb-4">Cancel Registration</div>
            {eligibleForRefund && (
              <div className="accent-text">
                Payment Date:{" "}
                {moment(acceptedRejectedDate).format("MMMM D, YYYY")}
                <br />
                Trip Fee ${tripFee}{" "}
                {creditsDescription ? `| ${creditsDescription} ` : ""}| Deposit
                ${depositAmount}
              </div>
            )}
            {eligibleForRefund && (
              <div className="refund-options">
                <div className="mb-8">Select the amount to refund</div>
                <Radio
                  name="refundType"
                  onChange={(_, val) => setRefundType(val)}
                  options={[
                    {
                      value: TripRegistrationRefundTypes.Full,
                      display: `Full refund (${tripFeeDescription} + deposit) (${getAmountDisplay(
                        total
                      )})`,
                      tag: (
                        <div className="accent-text ml-32">
                          For cancellations made within 1 business day after the
                          payment has been processed
                        </div>
                      ),
                    },
                    {
                      value: TripRegistrationRefundTypes.TripFee,
                      display: `${tripFeeDescription} only (${getAmountDisplay(
                        tripFee
                      )})`,
                      tag: (
                        <div className="accent-text ml-32">
                          For cancellations made more than 1 business day after
                          the payment has been processed up until the latest
                          cancellation date (
                          {moment(latestCancellationDate).format(
                            "MMMM D, YYYY"
                          )}
                          )
                        </div>
                      ),
                    },
                    {
                      value: TripRegistrationRefundTypes.None,
                      display: `No refund${noRefundDescription}`,
                      tag: (
                        <div className="accent-text ml-32">
                          For cancellations made after the latest cancellation
                          date (
                          {moment(latestCancellationDate).format(
                            "MMMM D, YYYY"
                          )}
                          )
                        </div>
                      ),
                    },
                  ]}
                  value={refundType}
                />
              </div>
            )}
            <div className="flex mt-16 mb-8">
              <i className="material-icons large-text trip-warning-icon mr-4">
                warning
              </i>{" "}
              This action cannot be undone.
            </div>
            <div className="flex">
              <i className="material-icons large-text trip-info-icon mr-4">
                info
              </i>{" "}
              The student will be notified via email.
            </div>
            <div className="modal-btns relative">
              <button
                className="link-text-secondary uppercase-text"
                disabled={loading}
                onClick={onCloseConfirmation}
              >
                Don't Cancel
              </button>
              <button
                className={`link-text-error uppercase-text ml-24${
                  !refundType || loading ? " disabled" : ""
                }`}
                disabled={!refundType || loading}
                onClick={onCancelRegistration}
              >
                {loading
                  ? "Cancelling..."
                  : refundType &&
                    refundType !== TripRegistrationRefundTypes.None
                  ? "Cancel & Refund"
                  : eligibleForRefund &&
                    refundType === TripRegistrationRefundTypes.None
                  ? "Cancel with no refund"
                  : "Cancel registration"}
              </button>
            </div>
            {!!errorMessage && (
              <div className="text-right">
                <span className="error-text">{errorMessage}</span>
              </div>
            )}
          </div>
        </Modal>
      ) : (
        <ConfirmationModal
          cancel={onCloseConfirmation}
          cancelText="Don't Cancel"
          className="trip-modal"
          confirm={onCancelRegistration}
          confirmText={
            refundType && refundType !== TripRegistrationRefundTypes.None
              ? "Cancel & Refund"
              : eligibleForRefund
              ? "Cancel with no refund"
              : "Cancel registration"
          }
          errorMessage={errorMessage}
          loading={loading}
          message={
            refundType && refundType !== TripRegistrationRefundTypes.None ? (
              `Are you sure you want to cancel this registration? \nThe student will be refunded the full amount of ${getAmountDisplay(
                total
              )} and will be notified via email.`
            ) : eligibleForRefund ? (
              <>
                Are you sure you want to cancel this registration with{" "}
                <b>NO REFUND</b>?
                <br />
                The student will be notified via email.
                <br />
                <br />
                If you would like to issue a full refund for this cancellation,
                you may cancel the registration and then issue a refund using
                the 'Issue Full Refund' button on this page.
              </>
            ) : (
              "Are you sure you want to cancel this registration?\nThe student will be notified via email."
            )
          }
          show={showConfirmationModal}
          title={
            <span className="flex flex-align-center">
              <i className="material-icons large-text trip-warning-icon mr-8">
                warning
              </i>
              <span>Warning</span>
            </span>
          }
        />
      )}
    </div>
  );
}

export default React.memo(RegistrationCancellation);
